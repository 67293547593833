<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课件配课统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="课件id" class="searchboxItem ci-full">
              <span class="itemLabel">课件id:</span>
              <el-input
                v-model="searchForm.kpointId"
                clearable
                size="small"
                placeholder="请输入课件id"
              ></el-input>
            </div>
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="searchForm.kpointName"
                clearable
                size="small"
                placeholder="请输入课件名称"
              ></el-input>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="exportSeeExaminationRoom"
              >导出</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="课件ID"
                align="center"
                prop="kpointId"
                show-overflow-tooltip
              />
              <el-table-column
                label="课件名称"
                align="center"
                prop="kpointName"
                show-overflow-tooltip
                min-width="150px"
              />
              <el-table-column
                label="视频时长"
                align="center"
                prop="kpointDurationStr"
                show-overflow-tooltip
                min-width="100px"
              />
              <el-table-column
                label="资源提供者"
                align="center"
                prop="compName"
                show-overflow-tooltip
                min-width="150px"
              />
              <el-table-column
                label="讲师姓名"
                align="center"
                prop="teacherName"
                show-overflow-tooltip
                min-width="120px"
              />
              <el-table-column
                label="课程名称"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="150px"
              />
              <el-table-column
                label="视频总时长"
                align="center"
                prop="kpointTotalDuration"
                show-overflow-tooltip
                min-width="120px"
              />
              <el-table-column
                label="总课时数"
                align="center"
                prop="lessonNum"
                show-overflow-tooltip
                min-width="120px"
              />
              <el-table-column
                label="课程来源"
                align="center"
                prop="courseOrigin"
                show-overflow-tooltip
                min-width="120px"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "coursewareWithClass",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {
      // 检索数据
      searchForm: {
        kpointId: "", // 课件id
        kpointName: "", // 课件名称
      },
    };
  },
  created() {
    
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.kpointId) {
        params.kpointId = this.searchForm.kpointId;
      }
      if (this.searchForm.kpointName) {
        params.kpointName = this.searchForm.kpointName;
      }
      this.doFetch(
        {
          url: "/biz/report/project/kpoint/course/pageList",
          params,
          pageNum,
        },
      );
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {
       
      };
      if (this.searchForm.kpointId) {
        params.kpointId = this.searchForm.kpointId;
      }
      if (this.searchForm.kpointName) {
        params.kpointName = this.searchForm.kpointName;
      }
      this.$post(
        "/biz/report/project/kpoint/course/export",
        params,
      )
        .then((res) => {
          const arr = [{...res.data}];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
  <style lang="less" scoped>
</style>